<template>
  <v-card>
    <v-toolbar dense color="primary">
      <v-toolbar-title class="white--text">Detalles impago Cod {{ detailsId }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn color="white" icon @click="detailsId = null">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-tabs v-model="tab">
      <v-tab>Control impago</v-tab>
      <v-tab>Detalles factura</v-tab>
      <v-tab v-if="$root.acceso('CONTROL_IMPAGADOS')">Avisos formales</v-tab>
      <v-tab v-if="$root.acceso('CONTROL_MONITORIO_IMPAGADOS')">Control Cesce</v-tab>
      <v-tab v-if="$root.acceso('CONTROL_MONITORIO_IMPAGADOS')">Control Gesico</v-tab>
      <v-tab v-if="$root.acceso('CONTROL_MONITORIO_IMPAGADOS')">Control Plan de Pagos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <detalles-impagados
          @changeIdCarteraCobro="v => { detailsId = v }"
          @close="() => { detailsId = null }"
          :IdCarteraCobro="detailsId"
          v-if="detailsId"
          :key="detailsId"
        />
      </v-tab-item>
      <v-tab-item>
        <detalles-factura
          @close="() => { detailsId = null }"
          :IdCarteraCobro="detailsId"
          v-if="detailsId"
          :key="detailsId"
        />
      </v-tab-item>
      <v-tab-item>
        <avisos-formales
          @close="() => { detailsId = null }"
          :IdCarteraCobro="detailsId"
          v-if="detailsId"
          :key="detailsId"
        />
      </v-tab-item>
      <v-tab-item>
        <control-cesce
          @close="() => { detailsId = null }"
          :IdCarteraCobro="detailsId"
          v-if="detailsId"
          :key="detailsId"
        />
      </v-tab-item>
      <v-tab-item>
        <control-gesico
          @close="() => { detailsId = null }"
          :IdCarteraCobro="detailsId"
          v-if="detailsId"
          :key="detailsId"
        />
      </v-tab-item>
      <v-tab-item>
        <control-plan-pagos
          @close="() => { detailsId = null }"
          :IdCarteraCobro="detailsId"
          v-if="detailsId"
          :key="detailsId"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  components: {
    DetallesFactura: () => import("@/modules/facturacion/views/DetallesFactura.vue"),
    DetallesImpagados: () => import("../views/DetallesImpagados.vue"),
    AvisosFormales: () => import("../views/AvisosFormales.vue"),
    ControlCesce: () => import("../views/ControlCesce.vue"),
    ControlGesico: () => import("../views/ControlGesico.vue"),
    ControlPlanPagos: () => import("../views/ControlPlanPagos.vue"),
  },
  props: {
    value: String | Number
  },
  data() {
    return {
      tab: 0
    }
  },
  computed: {
    detailsId: {
      get() { return this.value },
      set(v) { return this.$emit('input', v) }
    }
  }
}
</script>